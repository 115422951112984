export default function (etapa) {
  let window = 'prudutividade.etapa.edit-' + etapa.id
  !this.$uloc.window.get(window) && this.$uloc.window.new({
    wid: window,
    title: 'Modificar etapa ' + etapa.nome,
    width: '400',
    height: '800',
    minHeight: '400px',
    backdrop: false,
    move: true,
    clickOutside: true,
    windowClass: 'erp-window-modern v2',
    contentClass: '',
    props: {
      router: this.$router,
      _etapa: etapa
    }
  }, () => import('./Edit.vue'))
    .then((wid) => {
      console.log(wid)
      this.$uloc.window.listen(wid, {
        updated: (wid, data) => {
          console.log('Simple Person created event')
          this.updateCard(data)
          //this.newPersonCreated && this.newPersonCreated(data)
        }
      })
    }) // return wid
}
