export default function (etapa) {
  let window = 'prudutividade.etapa.edit-' + etapa.id
  !this.$uloc.window.get(window) && this.$uloc.window.new({
    wid: window,
    title: 'Registrar pagamento na etapa ' + etapa.nome,
    width: '500',
    height: '300',
    minHeight: '300px',
    backdrop: false,
    move: true,
    clickOutside: true,
    windowClass: 'erp-window-modern v2',
    contentClass: '',
    props: {
      router: this.$router,
      etapa: etapa
    }
  }, () => import('./Pagamento.vue'))
    .then((wid) => {
      console.log(wid)
      this.$uloc.window.listen(wid, {
        updated: (wid, data) => {
          console.log('Simple Person created event')
          this.updateCard(data)
          //this.newPersonCreated && this.newPersonCreated(data)
        },
        reloadProdutividade: (wid, data) => {
          console.log('Reload Event')
          this.reloadProdutividade && this.reloadProdutividade()
          //this.newPersonCreated && this.newPersonCreated(data)
        }
      })
    }) // return wid
}
