<template>
  <div @click="$emit('edit', intimacao)" class="produtividade-etapa-intimacao">
    <div class="datas">
      <div>
        <p class="title">Intimação</p>
        <p v-if="intimacao.dataIntimacao">{{ intimacao.dataIntimacao|formatDate('dd/MM/yyyy') }}</p>
        <p v-else>-</p>
      </div>
      <div>
        <p class="title">Prazo</p>
        <p v-if="intimacao.dataPrazo">{{ intimacao.dataPrazo|formatDate('dd/MM/yyyy') }}</p>
        <p v-else>-</p>
      </div>
      <div>
        <p class="title">Resposta</p>
        <p v-if="intimacao.dataResposta">{{ intimacao.dataResposta|formatDate('dd/MM/yyyy') }}</p>
        <p v-else>-</p>
      </div>
    </div>
    <div class="m-t" v-if="intimacao.observacao">
      {{intimacao.observacao}}
    </div>
  </div>
</template>

<script>
export default {
  name: "ItemIntimacao",
  props: ['intimacao']
}
</script>
