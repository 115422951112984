<template>
  <div class="c3 col-grow-1 text-right" v-if="pagamentosPessoa">Recebeu R${{ totalPago|moeda }}</div>
  <div class="c3 col-grow-1 text-right status-0" v-else>Nada pago</div>
</template>

<script>
export default {
  name: "CardPagamentos",
  props: ['pagamentos', 'etapa', 'pessoa'],
  computed: {
    pagamentosPessoa () {
      return this.findPagamentosByPessoa(this.pessoa.pessoa)
    },
    totalPago () {
      let total = 0
      this.pagamentosPessoa.map(pag => {
        total = total + Number(pag.valorPago)
      })
      return total
    }
  },
  methods: {
    findPagamentosByPessoa (pessoa) {
      if (!this.pagamentos || !this.pagamentos.length) return
      console.log(this.pagamentos)
      const pagamentos = this.pagamentos.filter(p => {
        return p.pessoa.id === pessoa.id
      })
      if (!pagamentos.length) return
      return pagamentos
    }
  }
}
</script>
