export default function (etapaId, intimacaoId = null) {
  let window = 'prudutividade.etapa.intimacao-' + intimacaoId
  !this.$uloc.window.get(window) && this.$uloc.window.new({
    wid: window,
    title: 'Adicionar intimacao ' + intimacaoId,
    width: '400',
    height: '500',
    minHeight: '400px',
    backdrop: false,
    move: true,
    clickOutside: true,
    windowClass: 'erp-window-modern v2',
    contentClass: '',
    props: {
      router: this.$router,
      etapaId,
      intimacaoId
    }
  }, () => import('./Intimacao.vue'))
    .then((wid) => {
      console.log(wid)
      this.$uloc.window.listen(wid, {
        updated: (wid, data) => {
          console.log('Simple Person created event')
          // this.updateCard(data)
          //this.newPersonCreated && this.newPersonCreated(data)
          this.reloadProdutividade && this.reloadProdutividade()
        }
      })
    }) // return wid
}
