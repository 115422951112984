<template>
  <div class="card">
    <strong class="title-item">{{ etapa.nome }}</strong>
    <div class="h-card status-concluido" v-if="etapa.status === 1">Etapa concluída {{ etapa.dataConclusao|formatDate }}</div>
    <div class="h-card status-pendente" v-else>Pendente</div>
    <div class="b-card">
      <div class="flex-show" v-if="!isCardIntimacao">
        <div class="r1 m-b-xs">
          <p><span style="font-weight: normal">Ciência Via</span>: {{ etapa.canalCiencia ? etapa.canalCiencia.nome : '-' }}</p>
          <p v-if="etapa.resumo">{{ etapa.resumo }}</p>
        </div>
        <div class="r1" v-if="processo">
          <!--<p class="title"><i class="fa fa-calendar" /> Data Protocolo</p>
          <p v-if="etapa.dataProtocolo">{{ etapa.dataProtocolo|formatDate('dd/MM/yyyy') }}</p>
          <p v-else>-</p>-->

          <p class="title m-t"><i class="fa fa-calendar" /> Data Ciência</p>
          <p v-if="etapa.dataCiencia">{{ etapa.dataCiencia|formatDate('dd/MM/yyyy') }}</p>
          <p v-else>-</p>

          <p class="title m-t"><i class="fa fa-calendar" /> Data <span v-if="etapa.nome === 'Indicação'">Indicação</span><span v-else>Manifestação Leiloeiro</span></p>
          <p v-if="etapa.dataManifestacao">{{ etapa.dataManifestacao|formatDate('dd/MM/yyyy') }}</p>
          <p v-else>-</p>
        </div>
        <div class="r2">
          <strong v-if="etapa.observacoes">Observações:</strong>
          <p v-if="etapa.observacoes">
            {{ etapa.observacoes }}. Por <a>{{ etapa.observacoesAutor }}</a>
          </p>
        </div>
      </div>
      <div class="r3" v-if="!isCardIntimacao">
        <strong>Responsáveis:</strong>
        <ul>
          <li style="margin-left: 0; padding-left: 0" v-if="!etapa.pessoas || !etapa.pessoas.length">Nenhum reponsável</li>
          <li v-for="p in etapa.pessoas" :key="p.id">
            <div class="flex items-center no-wrap hoverable">
              <div class="c1" style="width: 20px; height: 20px">
                <div @click="gotoPerson(p.pessoa)" class="person-avatar avatar min cursor-pointer">
                  <img v-if="p.pessoa.photo" :src="p.pessoa.photo">
                  <div v-else class="img-fake">{{ firstLettersName(p.pessoa.name) }}</div>
                </div>
              </div><!-- Cont Foto-->
              <div @click="gotoPerson(p.pessoa)" class="c2 cursor-pointer">{{ p.pessoa.name }} <a @click.prevent.stop="excluirPessoaRelacionada(p)" class="hidden-nohover text-negative font-10 m-l">Excluir</a> </div><!-- C2 -->
            </div>
            <card-pagamentos v-if="!processo" :pagamentos="pagamentos" :etapa="etapa" :pessoa="p" />
          </li><!---->
        </ul>
      </div>
      <div v-if="etapa.intimacoes" class="r1">
        <item-intimacao @edit="(i) => editaIntimacao(i)" v-for="intimacao in etapa.intimacoes" :intimacao="intimacao" :key="intimacao.id" />
      </div>
      <div>
        <anexo-etapa-input ref="uploader" :etapa="etapa" />
      </div>
    </div>
    <div class="f-card">
      <!--          <a @click='toggle = !toggle'>...</a>-->
      <u-btn @click="toggle = !toggle" round rounded flat icon="more_horiz"/>
    </div>

    <div class="card-actions" v-show="toggle">
      <ul>
        <li v-if="!isCardIntimacao">
          <a @click="$refs.popover.toggle()">Adicionar pessoa</a>
          <popover @update="updatePessoa" ref="popover" :etapa="etapa"/>
        </li>
        <li v-if="!processo && !isCardIntimacao"><a @click="pagar">Registrar um pagamento</a></li>
        <li v-if="!isCardIntimacao"><a @click="edit">Modificar etapa</a></li>
        <li v-if="isCardIntimacao"><a @click="adicionaIntimacao">Adicionar intimação</a></li>
      </ul>
    </div>
  </div>
</template>

<script>
import editWindow from './editWindow'
import pagamentoWindow from './pagamentoWindow'
import Popover from "@/components/processos/components/produtividade/PopoverPessoa"
import {getPagamentosEtapaPessoa, deletePessoaEtapa} from "@/domain/processos/services/produtividade"
import CardPagamentos from "@/components/processos/components/produtividade/CardPagamentos"
import AnexoEtapaInput from "@/components/processos/components/produtividade/AnexoEtapaInput";
import intimacaoWindow from "@/components/processos/components/produtividade/intimacaoWindow";
import ItemIntimacao from "@/components/processos/components/produtividade/ItemIntimacao";

export default {
  name: "Card",
  props: ['produtividade', 'etapa', 'processo'],
  components: {ItemIntimacao, AnexoEtapaInput, CardPagamentos, Popover},
  data() {
    return {
      toggle: false,
      loadingPagamentos: false,
      pagamentos: null
    }
  },
  computed: {
    isCardIntimacao () {
      return this.etapa.permiteIntimacoes
    }
  },
  mounted () {
    !this.processo && this.findPagamentos()
  },
  methods: {
    edit() {
      this.toggle = false
      this.etapa.produtividade = this.produtividade.id
      this.editWindow(this.etapa)
    },
    pagar() {
      this.pagamentoWindow(this.etapa)
    },
    editWindow,
    pagamentoWindow,
    updateCard(data) {
      this.$emit('updateCard', data)
    },
    updatePessoa(data) {
      this.updateCard(data.data)
    },
    firstLettersName(name) {
      name = name.split(' ')
      if (Array.isArray(name) && name.length > 1) {
        return name[0].charAt(0) + name[1].charAt(0)
      }
      return String(name).substr(0, 2)
    },
    gotoPerson(p, target = '_blank') {
      let router = this.$router || this.router
      let routeData = router.resolve({name: 'pessoa.show', params: {id: p.id}})
      window.open(routeData.href, '_blank')
    },
    reloadProdutividade() {
      this.findPagamentos()
      this.$emit('reloadProdutividade')
    },
    findPagamentos() {
      this.loadingPagamentos = true
      getPagamentosEtapaPessoa(this.etapa.id)
          .then(response => {
            this.loadingPagamentos = false
            this.pagamentos = response.data
            console.log(response.data)
          })
          .catch(error => {
            this.loadingPagamentos = false
            this.alertApiError(error)
          })
    },
    adicionaIntimacao () {
      this.intimacaoWindow(this.etapa.id)
    },
    editaIntimacao (intimacao) {
      this.intimacaoWindow(this.etapa.id, intimacao.id)
    },
    intimacaoWindow,
    excluirPessoaRelacionada (p) {
      this.$uloc.dialog({
        title: 'Excluir',
        message: 'Tem certeza que deseja remover esta pessoa desta etapa?',
        ok: 'Sim',
        cancel: 'Não',
        color: 'negative'
      }).then(() => {
        deletePessoaEtapa(this.etapa, p.id, 'DELETE')
          .then(response => {
            console.log(response)
            this.$emit('reloadProdutividade')
          })
          .catch(error => {
            this.alertApiError(error)
          })
      })
      .catch(() => {})
    }
  }
}
</script>
