<template>
  <u-popover :anchor-click="false" disable-close-on-click-outside ref="popover"
             class="window-context-menu erp-window erp-window-modern v2 e-input-modern label-size-2 size1"
             anchor="bottom left"
             self="top left">
    <div class="u-window-content wrapper-sm">
      <div class="w-m-content">
        <div>
          <p class="font-12 no-margin no-select" style="color: #293e52">Transferir</p>
        </div>
        <div>
          <autocomplete ref="autocomplete" autofocus @enter="salvar" :search="searchPerson" v-model="mock" icon="user"/>
        </div>
      </div>
    </div>
    <div class="w-m-footer footer-modern small">
      <u-btn @click="$refs.popover.hide()" label="Cancelar" no-caps
             :color="'white'"
             text-color="grey-8"
             class="b-radius-3px btn-white m-r-sm"/>
      <u-btn :loading="loading" @click="salvar" label="Salvar" no-caps
             :color="'green'"
             class="b-radius-3px"/>
    </div>
  </u-popover>
</template>

<script>
import {UPopover} from 'uloc-vue'
import Autocomplete from "@/components/layout/components/Autocomplete"
import {list} from '@/domain/pessoa/services'
import {updatePessoaEtapa} from "@/domain/processos/services/produtividade"
const searchPerson = function (terms) {
  let filtros = encodeURI(`&tipo=1&sortBy=name&descending=false&search=${terms}`)
  return list(50, 1, filtros)
}

export default {
  name: "PopoverOwner",
  props: ['etapa', 'label'],
  components: {UPopover, Autocomplete},
  data() {
    return {
      loading: false,
      mock: null
    }
  },
  methods: {
    toggle () {
      this.$refs.popover.toggle()
      this.$nextTick(() => {
        if (this.$refs.popover.showing) {
          this.$refs.autocomplete.focus()
        }
      })
    },
    searchPerson(terms, done) {
      searchPerson(terms)
          .then(response => {
            const extra = {
              label: `Adicionar "${terms}" como nova pessoa`,
              value: terms,
              isNew: true
            }
            let result = response.data.result.map(p => {
              return {
                ...p,
                label: p.name,
                value: p.name,
                active: null
              }
            })
            // result.push(extra)
            done(result)
          }).catch(error => {
        this.alertApiError(error)
      })
    },
    salvar() {
      if (!this.mock) return
      this.loading = true
      updatePessoaEtapa(this.etapa, {
            type: this.type,
            person: this.mock
          })
          .then(response => {
            this.loading = false
            this.$emit('update', {etapa: this.etapa, data: response.data})
            this.$uloc.notify({
              color: 'positive',
              message: `Etapa atualizada com sucesso.`,
              position: 'bottom-left',
            })
            this.$refs.popover.hide()
          })
          /*.catch(error => {
            this.loading = false
            this.alertApiError(error)
          })*/
    },
    desvincular () {
      this.loading = true
      // let processo = JSON.parse(JSON.stringify(this.processo))
      // processo[this.type] = null
      updatePessoaEtapa(this.etapa, {
        person: null // @TODO
      })
          .then(response => {
            this.loading = false
            this.$emit('update', {etapa: this.etapa, data: response.data})
            this.$uloc.notify({
              color: 'positive',
              message: `Etapa atualizada com sucesso.`,
              position: 'bottom-left',
            })
          })
          .catch(error => {
            this.loading = false
            this.alertApiError(error)
          })
    }
  }
}
</script>
