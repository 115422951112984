<template>
  <div class="produtividade-anexos m-t"
       :class="{'dropzoneEnter': dropzone}"
       @dragenter.prevent.stop="dropzoneEnter"
       @dragover.prevent.stop="dropzone = true"
       @drop.prevent.stop="inputListener"
       @dragleave.prevent.stop="dropzone = false">
    <input @change="inputListener($event)" :accept="fileTypes" type="file" id="input" ref="input" multiple
           v-show="false">
    <div class="produtividade-anexo-container non-selectable" v-if="!files.length && (!etapa.anexos || !etapa.anexos.length)">
      <div class="flex column items-center">
        <u-btn @click="$refs.input.click()" color="primary" icon="cloud-upload" size="sm"
               icon-type="fa" icon-style="light" noCaps
               label="Anexar arquivos"></u-btn>
        <div class="m-t">
          <span>ou solte os arquivos aqui <u-icon name="upload" type="fa" icon-style="light"
                                                  class="m-l-xs"/></span>
        </div>
      </div>
    </div>
    <div class="produtividade-anexos-list" v-else>
      <div v-for="(file, index) in etapa.anexos" :key="file.d" class="produtividade-anexo-item m-r-sm m-b-sm">
        <u-icon :name="getFileIco(file.filename)" type="fa" icon-style="solid" style="font-size: 220%">
        </u-icon>
        <u-tooltip :offset="[5,5]">{{ file.originalFilename }}</u-tooltip>
        <u-popover class="window-context-menu menu-profile min" anchor="bottom left" self="top left" :offset="[0, 5]">
          <div>
            <ul>
              <!--<menu-options-item label="Excluir" close />-->
              <menu-options-item @click.native="download(file, true)" label="Visualizar" close/>
              <menu-options-item @click.native="download(file)" label="Baixar" close/>
              <menu-options-item @click.native="excluir(file)" label="Excluir" close/>
            </ul>
          </div>
        </u-popover>
      </div>
      <div v-for="(file, index) in files" :key="'tmp-' + index" class="produtividade-anexo-item m-r-sm m-b-sm pos-rlt">
        <u-icon :name="getFileIco(file.filename)" type="fa" icon-style="solid">
        </u-icon>
        <u-tooltip :offset="[5,5]">{{ file.filename }}</u-tooltip>
        <div class="pos-abt absolute-bottom w-full" style="min-height: 2px">
          <u-progress v-if="file.copying" :percentage="file.progress" :animate="true"
                      :color="file.progress < 85 ? 'grey' : 'positive'" height="2px"/>
          <span class="m-b-xs wrapper-xs" v-else-if="file.fail"><a @click.prevent.stop="upload(file)"
                                                                   style="text-transform: none; color: red">Falha. Tentar novamente</a></span>
          <template v-else></template>
        </div>
        <u-popover class="window-context-menu menu-profile min" anchor="bottom left" self="top left" :offset="[0, 5]">
          <div>
            <ul>
              <!--<menu-options-item label="Excluir" close />-->
              <menu-options-item @click.native="excluir(file)" label="Excluir" close/>
            </ul>
          </div>
        </u-popover>
      </div>
      <div @click="$refs.input.click()" class="produtividade-anexo-item text-gre m-r-sm m-b-sm">
        <u-icon name="paperclip" type="fa" icon-style="solid"/>
        <u-tooltip :offset="[5,5]">Clique para anexar</u-tooltip>
      </div>
    </div>
    <div class="clearboth block full-width justify-center text-center" style="clear: both" v-if="existsFilesToUpload">
      <u-btn no-caps color="green" label="Enviar arquivos" @click="iniciaEnvios" />
    </div>
  </div>
</template>

<script>
import {downloadArquivoEtapa, uploadArquivoEtapa, deleteArquivoEtapa} from "@/domain/processos/services/produtividade"
import uploadAreaMixin from "@/reuse/input/upload-area-mixin"
import {UTooltip, UProgress, UPopover} from "uloc-vue"
import MenuOptionsItem from "@/components/layout/context-menu/context-window-options-item"

export default {
  name: "AnexoEtapaInput",
  mixins: [uploadAreaMixin],
  props: {
    etapa: {
      required: true
    }
  },
  data() {
    return {
      uploadService: (data) => uploadArquivoEtapa(this.etapa.id, data),
      downloadService: (arquivo, inline) => downloadArquivoEtapa(this.etapa, arquivo, true),
    }
  },
  methods: {
    uploadSuccessCallback(response) {

    },
    uploadFailureCallback(error) {

    },
    excluir (file) {
      console.log('Excluindo arquivo')
      if (!file.id) {
        this.files.splice(this.files.indexOf(file), 1)
      } else {
        this.$uloc.dialog({
          title: 'Excluir',
          message: 'Tem certeza que deseja remover este arquivo?',
          ok: 'Sim',
          cancel: 'Não',
          color: 'negative'
        }).then(() => {
          deleteArquivoEtapa(this.etapa.id, file.id)
            .then(response => {
              console.log(response)
              this.etapa.anexos.splice(this.etapa.anexos.indexOf(file), 1)
            })
          .catch(error => {
            this.alertApiError(error)
          })
        })
        .catch(() => {})
      }
    }
  },
  components: {
    UTooltip,
    UProgress,
    UPopover,
    MenuOptionsItem
  }
}
</script>
